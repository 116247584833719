<template>
  <div>
    <material-card
      color="general"
      :title="$t('Customer.title')"
      :text="$t('Customer.subtitle')"
    >
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="zwonogreen"
            dark
            class="mb-2 z-btn-top-right"
            v-on="on"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t("Common.new_item") }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-face-agent
            </v-icon>
            {{ $t("Customer.subtitle") }}
            <v-spacer />
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form 
              ref="editCustomerForm"
              v-model="editedItem.isValid"
            >
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.customer_name"
                      :rules="[rules.required]"
                      :label="$t('Common.customer_name')"
                    />
                    <v-text-field
                      v-model="editedItem.country"
                      :rules="[rules.required]"
                      :label="$t('Common.country')"
                    />
                    <v-text-field
                      v-model="editedItem.city"
                      :rules="[rules.required]"
                      :label="$t('Common.city')"
                    />
                    <v-text-field
                      v-model="editedItem.address1"
                      :rules="[rules.required]"
                      :label="$t('Common.street_name')"
                    />
                    <v-text-field
                      v-model="editedItem.house_number"
                      :rules="[rules.required]"
                      :label="$t('Common.house_number')"
                    />
                    <v-text-field
                      v-model="editedItem.phone_number"
                      :rules="[rules.required, rules.phoneNumber]"
                      :label="$t('Common.phone_number')"
                    />
                    <v-text-field
                      v-model="editedItem.email"
                      :rules="[rules.required, rules.email]"
                      :label="$t('Common.email')"
                    />
                    <div v-if="editedIndex >= 0">
                      <p v-if="editedItem.is_geocoded">
                        <v-icon color="green">
                          mdi-check-all
                        </v-icon>
                        {{ $t("Customer.is_geocoded") }}
                      </p>
                      <p v-else>
                        <v-icon color="red">
                          mdi-alert-octagon
                        </v-icon>
                        {{ $t("Customer.is_not_geocoded") }}
                      </p>
                    </div>
                    <v-layout wrap>
                      <v-btn
                        color="blue"
                        @click="changeGetAddressModalVisiblity(true)"
                      >
                        {{ $t('Order.select_coordinates_on_map') }}
                      </v-btn>
                    </v-layout>
                    <v-flex>
                      <v-text-field
                        v-model="editedItem.lat"
                        :rules="[rules.required, rules.latitude]"
                        :label="$t('Order.latitude')"
                      />
                      <v-text-field
                        v-model="editedItem.lon"
                        :rules="[rules.longitude]"
                        :label="$t('Order.longitude')"
                      />
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <v-alert
              v-if="alertTextEdit"
              dense
              type="error"
            >
              {{ alertTextEdit }}
            </v-alert>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              @click="close"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t("Common.cancel") }}
            </v-btn>
            <v-btn
              color="zwonogreen"
              class="pr-5"
              :loading="isSaving"
              :disabled="!editedItem.isValid"
              @click="save"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ $t("Common.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div>
        <v-text-field
          v-model="paginationOptions.search"
          append-icon="mdi-magnify"
          :label="$t('Common.search')"
          single-line
          hide-details
          solo
          class="z-search mb-4"
        />
        <v-data-table
          :loading-text="$t('Common.loading-text')"
          :headers="headers"
          :items="customerList ? customerList : []"
          :options.sync="paginationOptions"
          :server-items-length="(customerListMeta?.itemCount) ? customerListMeta.itemCount : 0"
          :loading="paginationLoading"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          class="elevation-1"
        >
          <!-- change table header color(or other properties) -->
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-light text-general text--darken-3"
              v-text="header.text"
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-green-color mr-2"
                  v-bind="attrs"
                  @click="editItem(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.edit") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-hover-red-color"
                  v-bind="attrs"
                  @click="showDeleteModal(item)"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.delete") }}</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <v-btn
              color="zwonogreen"
              @click="initialize"
            >
              <v-icon left>
                mdi-refresh
              </v-icon>
              Reset
            </v-btn>
          </template>
        </v-data-table>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              class="mx-2 pa-0"
              fab
              dark
              color="zwonogreen"
              v-bind="attrs"
              @click="custpref"
              v-on="on"
            >
              <v-icon dark>
                mdi-format-list-bulleted-square
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Customer.manage_customer_prefs") }}</span>
        </v-tooltip>
      </div>
    </material-card>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDelete"
      :is-visible="isDeleteModalVisible"
      @is-visible="(value) => (isDeleteModalVisible = value)"
      @confirm-btn-click="() => deleteItem()"
    />
    <get-address-modal
      :is-visible="isGetAddressModalVisible"
      :is-saving="false"
      :title="$t('Order.select_coordinates_on_map') + ': ' + (editedItem?.address1 + (editedItem?.house_number ? ` ${editedItem?.house_number}`: '') + (editedItem?.city ? `, ${editedItem?.city}`: '') + (editedItem?.country ? `, ${editedItem?.country}`: ''))"
      :prefill="editedItem?.address1 + (editedItem?.house_number ? ` ${editedItem?.house_number}`: '')"
      @is-visible="(value) => changeGetAddressModalVisiblity(value)"
      @address-object="(item) => changeAddressObject(item)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefaultPopup from "../../components/DefaultPopup.vue";
import GetAddressModal from "../../components/modals/GetAddressModal.vue";
import validationMixin from "../../components/helper/validationMixin";

export default {
  components: {
    DefaultPopup,
    GetAddressModal
  },

  mixins: [validationMixin],

  data: () => ({
    dialog: false,
    isDeleteModalVisible: false,
    isGetAddressModalVisible: false,
    deleteModalItemId: 0,
    editedIndex: -1,
    editedItem: {
      isValid: true,
      customer_name: "",
      country: "",
      city: "",
      address1: "",
      house_number: "",
      phone_number: "",
      email: "",
      lat: "",
      lon: "",
      is_geocoded: false,
    },
    defaultItem: {
      isValid: true,
      customer_name: "",
      country: "",
      city: "",
      address1: "",
      house_number: "",
      phone_number: "",
      email: "",
      lat: "",
      lon: "",
      is_geocoded: false,
    },
    alertTextEdit: "",
    alertTextDelete: "",
    isSaving: false,
    // table:
    paginationLoading: false,
    paginationOptions: {},
    oldSearchValue: '',
  }),

  computed: {
    ...mapGetters("customer", ["customerList", "customerListMeta"]),

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    headers() {
      return [
        { text: this.$t("Common.customer_name"), value: "customer_name" },
        { text: this.$t("Common.country"), value: "country" },
        { text: this.$t("Common.city"), value: "city" },
        { text: this.$t("Common.street_name"), value: "address1" },
        { text: this.$t("Common.house_number"), value: "house_number" },
        { text: this.$t("Common.phone_number"), value: "phone_number" },
        { text: this.$t("Common.email"), value: "email" },
        // { text: 'Company', value: 'company.company_name' },
        { text: this.$t("Common.actions"), value: "actions", sortable: false },
      ];
    },
  },

  watch: {
    dialog(val) {
      this.alertTextEdit = "";
      if (val) this.$nextTick(() => {this.$refs.editCustomerForm.validate()});
      else this.close();
    },
    paginationOptions: {
      handler(val) {
        if(val.search !== this.oldSearchValue) this.paginationOptions.page = 1
        this.oldSearchValue = val.search;
        this.initialize();
      },
      deep: true, // Watch changes deeply in the object
    },
  },

  // lifecycle hooks
  beforeDestroy() {
    this.clearCustomerState();
  },

  methods: {
    ...mapActions("customer", [
      "getCustomerList",
      "createCustomer",
      "updateCustomer",
      "deleteCustomer",
      "clearCustomerState",
    ]),

    async initialize() {
      this.paginationLoading = true;
      this.paginationOptions.role = sessionStorage.getItem("role");
      await this.getCustomerList(this.paginationOptions);
      this.paginationLoading = false;
    },

    editItem(item) {
      this.editedIndex = this.customerList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    showDeleteModal(item) {
      this.alertTextDelete = "";
      this.isDeleteModalVisible = true;
      this.deleteModalItemId = item?.id;
    },

    async deleteItem() {
      if (this.deleteModalItemId) {
        const isDeleted = await this.deleteCustomer(this.deleteModalItemId);
        if (isDeleted) {
          this.isDeleteModalVisible = false;
        } else {
          this.alertTextDelete = this.$t("Product.alerts.error-delete");
        }
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    async save() {
      this.isSaving = true;
      this.alertTextEdit = "";
      if (this.editedIndex > -1) {
        if (this.editedItem.lat && this.editedItem.lon) this.editedItem.is_geocoded = true;
        const errorMsg = await this.updateCustomer(this.editedItem);
        if (!errorMsg) {
          this.close();
        } else {
          if (errorMsg === 'phone_number') {
            this.alertTextEdit = this.$t("Customer.alerts.error-phone-number");
          } else {
            this.alertTextEdit = this.$t("Customer.alerts.error-edit");
          }
        }
      } else {
        if (this.editedItem.lat && this.editedItem.lon) this.editedItem.is_geocoded = true;
        const errorMsg = await this.createCustomer(this.editedItem);
        if (!errorMsg) {
          this.close();
        } else {
          if (errorMsg === 'phone_number') {
            this.alertTextEdit = this.$t("Customer.alerts.error-phone-number");
          } else {
            this.alertTextEdit = this.$t("Customer.alerts.error-edit");
          }
        }
      }
      this.isSaving = false;
    },

    custpref() {
      this.$router.push("customer_preferences");
    },

    async changeGetAddressModalVisiblity(isVisible) {
      this.isGetAddressModalVisible = isVisible
    },

    changeAddressObject(addressObj) {
      this.editedItem.lat = addressObj.lat;
      this.editedItem.lon = addressObj.lon;
      
      this.changeGetAddressModalVisiblity(false);
    }
  },
};
</script>
